export default {
    systemName: '智慧照明管理系统',
    loginByAccount: '账户密码登录',
    loginByPhone: '手机号登录',
    phoneNumber: '手机号',
    verificationCode: '验证码',
    account: '账户',
    password: '密码',
    getCode: '获取验证码',
    resend: '重新发送',
    loginButton: '登录',
    logout: '退出登录',
    pleaseEnterAccount: '请输入账户',
    pleaseEnterPassword: '请输入密码',
    pleasevalidCodeDiv: '请输入验证码',
    pleaseEnterTheVerificationCode: '请输入验证码',
    pleaseEnterPhoneNumber: '请输入手机号码',
    pleaseEnterCorrectPhoneNumber: '请输入正确的手机号码',
    theVerificationCodeHasBeenSent: '验证码已发送',
    newPassword: '新密码',
    passwordChangedSuccessfully: '密码修改成功',
    passwordChangeFailed: '密码修改失败',
    toggleLight: '开关灯',
    brillianceControl: '亮度',
    relay: '继电器',
    cctControl: '色温',
    colorControl: '颜色',
    modifyColor: '修改颜色',
    fileDistribution: '档案下发',
    configurationModificationSucceeded: '修改配置成功',
    configurationModificationFailure: '修改配置失败',
    fileDeliverySucceeded: '下发档案成功',
    fileDeliveryFailure: '下发档案失败',
    failedToObtainTheDeviceStatus: '获取设备状态失败',
    confirm: '确定',
    cancel: '取消',
    search: '查询',
    reset: '重置',
    createAccount: '新建账户',
    status: '状态',
    normal: '正常',
    forbidden: '禁用',
    operate: '操作',
    resetPasswords: '重置密码',
    edit: '编辑',
    detail: '详情',
    accountName: '账户名称',
    registrationDate: '注册时间',
    date: '时间',
    startDate: '开始日期',
    endDate: '结束日期',
    accountNo: '用户ID',
    roles: '角色',
    superiorRole: '上级角色',
    updateDate: '更新时间',
    createDate: '创建时间',
    statusChangeSucceeded: '状态变更成功',
    pleaseSelectStatus: '请选择状态',
    project: '项目',
    pleaseSelectRoles: '请选择用户角色',
    pleaseSelectSuperiorRole: '请选择上级角色',
    pleaseSelectProjects: '请选择用户所属项目',
    accountNamesCanOnlyBeAlphanumericAndNumeric: '账号名称只能为字母大小写和数字',
    cannotContainChineseChineseCharactersAndSpaces: '不能含有中文、中文字符以及空格',
    createdAccountSuccessfully: '新建账号成功',
    modifiedAccountInformationSuccessfully: '修改账号信息成功',
    createTenant: '新建运营商',
    tenantName: '运营商名称',
    uniformCreditCode: '统一信用代码',
    description: '描述',
    pleaseEnterTenantName: '请输入运营商名称',
    pleaseEnterAccountNo: '请输入用户ID',
    tenantFullName: '运营商全称',
    businessLicense: '营业执照',
    area: '省市区',
    address: '详细地址',
    contactWay: '联系方式',
    cannotContainSpecialCharacters: '不能包含特殊字符',
    pleaseEnterUniformCreditCode: '请输入统一信用代码',
    pleaseEnterTenantFullName: '请输入运营商全称',
    pleaseSelectArea: '请选择所在地区',
    pleaseUploadLogo: '请上传logo',
    pleaseUploadBusinessLicense: '请上传营业执照',
    pleaseEnterDescription: '请输入描述',
    pleaseEnterAddress: '请输入详细地址',
    createTenantSuccessfully: '新建运营商成功',
    modifiedTenantInformationSuccessfully: '修改运营商信息成功',
    uploadFileSizeCannotExceed1M: '上传文件大小不能超过1M',
    onlyJpgPngImagesCanBeUploaded: '上传图片类型只能是 jpg/png 格式',
    createRole: '新建角色',
    permissionSetting: '权限设置',
    roleName: '角色名称',
    index: '序号',
    pleaseEnterRoleName: '请输入角色名称',
    roleCode: '角色代码',
    roleDesc: '角色描述',
    onlyContainLetterCasesNumbersAndUnderscores: '只能含有字母大小写、数字以及下划线',
    pleaseEnterRoleCode: '请输入角色代码',
    pleaseEnterRoleDesc: '请输入角色描述',
    createRoleSuccessfully: '新建角色成功',
    modifiedRoleInformationSuccessfully: '修改角色信息成功',
    createType: '新建类型',
    typeName: '型号名称',
    typeDesc: '类型描述',
    confirmWhetherToDeleteTheType: '确认是否删除该类型',
    confirmWhetherToDelete: '确认是否删除',
    successfullyDelete: '删除成功',
    del: '删除',
    pleaseEnterTypeName: '请输入类型名称',
    pleaseEnterTypeDesc: '请输入类型描述',
    createTypeSuccessfully: '新建类型成功',
    modifiedTypeInformationSuccessfully: '修改类型信息成功',
    createProject: '新增项目',
    projectName: '项目名称',
    province: '省份',
    pleaseSelectProvince: '请选择省份',
    projectType: '项目类型',
    parentProject: '父项目',
    longitude: '经度',
    pleaseEnterLongitude: '请输入经度',
    latitude: '纬度',
    pleaseEnterLatitude: '请输入纬度',
    projectDesc: '项目描述',
    pleaseSelectParentProject: '请选择所属父项目',
    pleaseEnterProjectName: '请输入项目名称',
    pleaseSelectProjectType: '请选择项目类型',
    createProjectSuccessfully: '新建项目成功',
    modifiedProjectInformationSuccessfully: '修改项目信息成功',
    parentType: '父类型',
    pleaseSelectParentType: '请选择父类型',
    deviceType: '设备类型',
    pleaseEnterDeviceType: '请输入设备类型',
    pleaseSelectDeviceType: '请选择设备类型',
    createGateway: '新建网关设备',
    controlPanel: '控制面板',
    sendGroup: '分组下发',
    gatewayId: '网关设备Id',
    pleaseEnterGatewayId: '请输入网关设备id',
    gatewayType: '网关类型',
    pleaseSelectGatewayType: '请选择网关类型',
    offLine: '离线',
    onLine: '在线',
    error: '异常',
    pleaseSelectProject: '请选择项目',
    gatewayName: '网关名称',
    groupBelonging: '所属分组',
    confirmWhetherToDeleteTheGateway: '确认是否删除该网关',
    gatewayICCID: '网关ICCID',
    pleaseEnterGatewayICCID: '请输入网关ICCID',
    pleaseEnterGatewayName: '请输入网关名称',
    createGatewaySuccessfully: '新建网关成功',
    modifiedGatewayInformationSuccessfully: '修改设备信息成功',
    createGatewayGroup: '新增网关分组',
    groupName: '分组名称',
    pleaseEnterGroupName: '请输入分组名称',
    pleaseEnterTagName: '请输入标签名称',
    pleaseSelectGroupName: '请选择分组',
    gatewayDevice: '网关设备',
    pleaseSelectGateway: '请选择网关设备',
    confirmWhetherToDeleteTheGatewayGroup: '确认是否删除该网关分组',
    createGatewayGroupSuccessfully: '新建网关分组成功',
    modifiedGatewayGroupInformationSuccessfully: '修改网关分组信息成功',
    createNode: '新建设备',
    nodeType: '设备类型',
    pleaseSelectNodeType: '请选择设备类型',
    nodeId: '设备ID',
    pleaseEnterNodeId: '请输入设备id',
    nodeName: '设备名称',
    pleaseEnterNodeName: '请输入设备名称',
    confirmWhetherToDeleteTheNode: '确认是否删除该设备',
    createNodeSuccessfully: '新建设备成功',
    modifiedNodeInformationSuccessfully: '修改设备信息成功',
    createNodeGroup: '新建分组',
    createTag: '新建标签',
    nodeDevice: '设备',
    confirmWhetherToDeleteTheNodeGroup: '确认是否删除该分组',
    pleaseSelectNode: '请选择设备',
    createNodeGroupSuccessfully: '新建分组成功',
    modifiedNodeGroupInformationSuccessfully: '修改分组信息成功',
    menuPermissions: '菜单权限',
    buttonPermissions: '按钮权限',
    theRolePermissionsWereSetSuccessfully: '设置角色权限成功',
    strategyName: '策略名称',
    createControl: '新建控制',
    distribution: '下发',
    distriproject: '下发项目',
    distriStatus: '下发状态',
    finishTime: '完成时间',
    distriTime: '下发时间',
    log: '日志',
    pleaseEnterStrategyName: '请输入策略名称',
    pleaseSelectStrategyName: '请选择策略',
    createStrategy: '新建策略',
    executionCycle: '执行时间',
    whetherToRepeat: '是否重复',
    executeOperation: '执行操作',
    periodOfValidity: '有效期',
    alarmField: '告警字段',
    upperLimit: '上限值',
    lowerLimit: '下限值',
    strategyType: '策略类型',
    pleaseSelectStrategyType: '请选择策略类型',
    deviceCategory: '设备种类',
    pleaseSelectDeviceCategory: '请选择设备种类',
    takeEffect: '立即生效',
    checkAll: '全选',
    monday: '周一',
    tuesday: '周二',
    wednesday: '周三',
    thursday: '周四',
    friday: '周五',
    saturday: '周六',
    sunday: '周日',
    strategyInformation: '策略信息',
    strategyContent: '策略内容',
    property: '服务名称',
    attribute: '属性名称',
    pleaseSelectProperty: '请选择属性',
    value: '值',
    executionTime: '执行时间',
    pleaseSelectExecutionTime: '请选择执行时间',
    operatingMode: '操作方式',
    pleaseSelectOperatingMode: '请选择操作方式',
    alarmContent: '告警内容',
    opened: '开启',
    closed: '关闭',
    confirmWhetherToDeleteTheAlarmStrategy: '确认是否删除该告警策略',
    add: '添加',
    createStrategySuccessfully: '新增策略成功',
    modifiedStrategyInformationSuccessfully: '修改策略信息成功',
    pleaseSelectSecondLevelGatewayType: '请选择二级网关类型',
    pleaseSetAlarmContent: '请设置告警内容',
    confirmWhetherToDeleteTheTaskStrategy: '确认是否删除该任务策略',
    set: '设置',
    true: '是',
    false: '否',
    setProperty: '设置属性',
    setReportingTask: '设置为上报任务',
    pleaseSetStrategyContent: '请设置策略内容',
    pleaseSelectExecutionCycle: '请选择执行周期',
    notDelivered: '未下发',
    delivering: '下发中',
    deliverySuccessful: '下发成功',
    deliveryFailure: '下发失败',
    confirmWhetherToDeleteTheTask: '确认是否删除该任务',
    confirmWhetherToDeliveryTheTask: '确认是否下发任务',
    alarmDistribution: '告警策略下发',
    alarmStrategy: '告警策略',
    deliveryAlarmStrategyTime: '下发告警策略时间',
    documentDeliveryTime: '档案下发时间',
    deviceModel: '设备模型',
    pleaseSelectDeviceModel: '请选择设备模型',
    gatewayModel: '网关型号',
    pleaseEnterGatewayModel: '请输入网关型号',
    power: '功率',
    pleaseEnterPower: '请输入功率',
    download: '下载',
    createOtaPackage: '新增OTA包',
    otaName: 'OTA名称',
    fileSize: '文件大小',
    fileName: '文件名称',
    pleaseEnterOtaName: '请输入OTA名称',
    confirmWhetherToDeleteTheOtaPackage: '确认是否删除该OTA包',
    softwareVersionName: '软件版本名称',
    softwareVersionNum: '软件版本号',
    upgradeType: '升级类型',
    baseVersionNum: '基准版本号',
    verification: '校验方式',
    sign: '签名',
    fullUpgrade: '全量升级',
    incrementalUpgrade: '增量升级',
    otaInformation: 'OTA信息',
    otherInformation: '其他信息',
    pleaseEnterSoftwareVersionName: '请输入软件版本名称',
    pleaseEnterSoftwareVersionNum: '请输入软件版本号',
    pleaseSelectVerification: '请选择校验方式',
    pleaseSelectUpgradeType: '请选择升级类型',
    pleaseEnterBaseVersionNum: '请输入基准版本号',
    uploadUpgradePackage: '上传升级包',
    pleaseUploadUpgradePackage: '请上传升级包',
    createOatPackageSuccessfully: '新建OAT包成功',
    modifiedOatPackageInformationSuccessfully: '修改OAT包信息成功',
    nodeInfo: '节点信息',
    gatewayInfo: '设备信息',
    Length12: '字符长度为12',
    map: '地图',
    downloadImportingTemplates: '下载导入模板',
    importingNodeDevice: '导入节点设备',
    importingSuccessfully: '导入成功',
    tip: '提示',
    untreated: '未处理',
    processing: '处理中',
    processed: '已处理',
    faultWarning: '故障告警',
    upperLimitAlarm: '上限告警',
    lowerLimitAlarm: '下限告警',
    eventType: '告警类型',
    pleaseSelectEventType: '请选择告警类型',
    deviceName: '设备名称',
    deviceId: '设备ID',
    pleaseEnterDeviceId: '请输入设备ID',
    operator: '操作人',
    projectInformation: '项目信息',
    alarmNotifyInformation: '告警通知信息',
    alarmNotifyWay: '告警通知方式',
    phone: '手机',
    email: '邮箱',
    wxCom: '企微',
    pleaseEnterPhone: '请输入手机号，输入多个请用";"分割',
    pleaseEnterEmail: '请输入邮箱号，输入多个请用";"分割',
    pleaseEnterWxCom: '请输入企微号，输入多个请用";"分割',
    deviceStatistics: '设备统计',
    projectNumber: '项目数量',
    gatewayNumber: '网关数量',
    allNodeNumber: '总节点数量',
    onlineNodeNumber: '在线节点数量',
    faultyDeviceNumber: '告警设备数量',
    energyStatistics: '能源统计',
    alarmEvents: '告警事件',
    more: '更多',
    alarm: '告警',
    node: '节点',
    failureEquipment: '故障设备',
    onlineRate: '在线率',
    faultRate: '故障率',
    electricityConsumption: '用电数据',
    energyConservation: '节能数据',
    dayAvgPower: '近一天平均总功率',
    lastWeek: '最近7天',
    lastMonth: '最近一个月',
    last3Months: '最近三个月',
    TheTimeHorizonCannotBeGreaterThan180Days: '时间范围不能大于180天',
    currentPosition: '当前经纬度',
    NumberOfAssociatedNodes: '关联节点数',
    refresh: '刷新',
    updateTime: '更新时间',
    batchDelete: '批量删除',
    validCode: '验证码',
    assetName: '资产名称',
    pleaseEnterAssetName: '请输入资产名称',
    macAddress: 'mac地址',
    pleaseEnterMacAddress: '请输入MAC地址',
    useStatus: '状态',
    pleaseEnterAssetType: '请输入资产种类',
    assetType: '资产种类',
    assetTypeId: '资产型号',
    assetTypeName: '资产型号',
    assetCategory: '资产类别',
    installDate: '安装日期',
    createAsset: '新增资产',
    dateOfProduction: '出厂日期',
    pleaseSelectAssetCategory: '请选择资产种类',
    pleaseSelectAssetTypeId: '请选择资产型号',
    pleaseSelectdateOfProduction: '请选择出厂日期',
    createAssetSuccess: '创建资产成功',
    editAssetSuccess: '修改资产成功',
    DeterminewhetherToDeleteThisAsset: '确定是否删除此资产',
    installAndUse: '安装',
    scrap: '报废',
    registration: '登记',
    ConfirmToScrapThisAsset: '确定报废此资产吗',
    transfer: '转移',
    PleaseSelectTheOperator: '请选择运营商',
    changeSuccessTenant: '转移成功',
    AssetImport: '资产导入',
    Operator: '运营商',
    PleaseSelectAnOperator: '请选择运营商',
    requisition: '批量领用',
    errorSelectionAsset: '请选择需要领用的资产',
    PleaseSelectACollectionAccount: '请选择账号',
    CollectingAccount: '领用账号',
    ReturnAccount: '归还账号',
    SuccessfullyCollected: '领用成功',
    SuccessfullyReturned: '归还成功',
    PleaseSelectAregisteredAssetCollection: '请选择已登记的资产领用',
    BatchScrapping: '批量报废',
    PleaseSelectAssetsThatHaveNotBeenScrapped: '请选择未报废的资产',
    PleaseSelectTheAssetThatNeedsToBeScrapped: '请选择需要报废的资产',
    PleaseSelectTheAssetsThatNeedToBeTransferred: '请选择需要移交的资产',
    BatchHandover: '批量移交',
    return: '归还',
    PleaseSelectTheAssetsThatHaveBeenCollected: '请选择已领用的资产',
    PleaseSelectTheAssetsThatNeedToBeReturned: '请选择需要归还的资产',
    PleaseSelectARegisteredAssetTransfer: '请选择已登记的资产移交',
    Recipient: '领用人',
    use: '领用',
    EquipmentModel: '设备型号',
    NodeModel: '型号',
    PleaseSelectTheDeviceModel: '请选择设备型号',
    PleaseSelectANodeModel: '请选择型号',
    PleaseSelectAtLeastOneItem: '请至少选择一项',
    TriggerType: '触发类型',
    PleaseSelectTheTriggerType: '请选择触发类型',
    PleaseEnterAttributeValues: '请输入属性值',
    PleaseSelectTheExecutionTime: '请选择执行时间',
    PleaseSelectAnAttributeName: '请选择属性名称',
    ObjectModelManagement: '物模型管理',
    ObjectModelList: '物模型列表',
    modelName: '模型名称',
    modelAdd: '新增物模型',
    ObjectModelName: '物模型名称',
    PleaseEnterTheObjectModelName: '请输入物模型名称',
    serviceId: '服务id',
    PleaseEnterServiceId: '请输入服务id',
    PleaseEnterServiceName: '请输入服务名称',
    ServiceConfiguration: '服务配置',
    AlarmServiceConfiguration: '告警服务配置',
    serviceType: '服务code',
    PleaseEnterServiceType: '请输入服务code',
    PleaseEnterciid: '请输入ciid',
    attributeCode: '属性code',
    PleaseAttributeCode: '请输入属性code',
    attributeName: '属性名称',
    PleaseEnterAttributeName: '请输入属性名称',
    AttributeTypeList: '属性类型',
    PleaseSelectAttributeTypeList: '请选择属性类型',
    AttributeDataType: '数据类型',
    PleaseSelectAttributeDataType: '请选择数据类型',
    MaximumValue: '最大值',
    PleaseEnterMaximumValue: '请输入最大值',
    minimumValue: '最小值',
    PleaseEnterminimumValue: '请输入最小值',
    step: '步长',
    PleaseEnterStep: '请输入步长',
    unit: '单位',
    PleaseEnterUnit: '请输入单位',
    AlarmType: '告警类型',
    PleaseSelectAlarmType: '请选择告警类型',
    SuccessfullyAddedObjectModel: '新增物模型成功',
    SuccessfullyModifiedTheObjectModel: '修改物模型成功',
    AddService: '添加服务',
    deleteService: '删除服务',
    AddAttribute: '添加属性',
    deleteAttribute: '删除属性',
    service: '服务',
    AlarmService: '告警服务',
    attribute2: '属性',
    PleaseEnterPhysicalModel: '请输入物模型名称',
    enum: '枚举值',
    PleaseEnterEnum: '请输入枚举值',
    ViewObjectModel: '物模型配置',
    isShow: '是否可见',
    Thereiscurrent: '此类型暂无物模型，在此新增',
    AssetModificationSuccessful: '资产修改成功',
    PleaseSelectServiceName: '请选择服务名称',
    PleaseDoNotAddAgain: '请不要重复添加',
    gateway: '网关',
    light: '智能灯',
    importTemplate: '导入模板',
    AreYouSureYouWantToDeleteThisObjectModel: '确定是否删除此物模型？',
    EnergyConsumptionConfiguration: '能耗配置',
    ObjectModelProperties: '物模型属性',
    operatorSymbol: '操作符',
    constant: '常量',
    addrealityOperator: '添加操作',
    addratedOperator: '添加额定操作',
    RulePreview: '规则预览',
    ActualElectricityConsumptionRules: '实际用电量规则',
    RulesForRatedElectricityConsumption: '额定用电量规则',
    SuccessfullyEditedEnergyConsumption: '编辑能耗成功',
    projectTypeName: '类型名称',
    sbness: '有人亮度',
    nbness: '无人亮度',
    illDelay: '亮灯范围',
    illRange: '亮灯延时',
    readText: '我已阅读并同意',
    yhxy: '澜潮用户服务协议',
    and: '和',
    yszc: '澜潮隐私策略',
    PleaseReadTheUserAgreementAndPrivacyPolicy: '请阅读用户协议和隐私政策',
    maintenance: '维修',
    MaintenanceInformation: '维修信息',
    RepairType: '维修类型',
    FaultDescription: '故障描述',
    PleaseEnterAFaultDescription: '请输入故障描述',
    ImageAttachment: '图片附件',
    PleaseSelectARepairType: '请选择维修类型',
    PleaseUploadPictures: '请上传图片',
    successfullySubmittedRepair: '提交维修成功',
    resetPassword: '重置密码',
    pleaseEnterNewPassword: '请输入新的密码',
    AreYouSureYouWantToResetYourPssword: '确定要重置密码吗',
    ModelCode: '型号代码',
    PleaseEnterTheModelCode: '请输入型号代码',
    timeFrame: '时间范围',
    dataStatistics: '数字化大屏',
    ChangeBinding: '绑定模式',
    WhetherToStartBindingMode: '开启/关闭绑定模式',
    Duration: '持续时间（秒）',
    PleaseFillInTheDuration: '请填写持续时间',
    deviceCategoryValue: {
        GATEWAY: '网关',
        GATEWAY_LIGHT: '网关灯',
        LIGHT: '智能灯',
    },
    lastLoginTime: '最近上线时间',
    lastLogoutTime: '最近下线时间',
    refreshGatewaySuccess: '刷新网关节点成功',
    refreshGatewayFail: '刷新网关节点失败',
    refreshSuccess: '刷新网关节点成功',
    refreshFail: '刷新网关节点失败',
    meterData: '电表数据',
    collectData: '采集数据',
    dataIndicator: '数据指标',
    pleaseSelectDataIndicator: '请选择数据指标',
    notEmptyProject: '项目不能为空',
    protocol: '通信通道',
    pleaseSelectProtocol: '请选择通信通道',
    protocolConfig: '协议配置',
    keyOrValueNotEmpty: '不能有空值',
    acquStatus: '采集任务启用状态:',
    acquFrequency: '采集频率(每隔多少时间):',
    notEmptyUnit: '单位不能为空',
    notEmptyValue: '频率不能为空',
    acquisitionConfig: '采集配置',
    acquisitionConfigSuccess: '采集配置设置成功',
    acquProp: '采集指标:',
    all: '全部',
    warningAcquisitionConfigEnable: '采集任务已开启,请先在采集配置里关闭采集任务',
    builders: '施工人员',
    managers: '管理人员',
    acceptors: '验收人员',
    ops: '运维人员',
    deviceCount: '原灯总数',
    powerLabel: '原灯功率(W)',
    pleaseEnterPowerLabel: '请输入原灯功率(W)',
    directIllumination: '原直射照度(LUX)',
    obliqueIllumination: '原斜射照度(LUX)',
    beforeRemodel: '改造前照度(LUX)',
    afterRemodel: '改造后照度(LUX)',
    pleaseSelect: '请选择',
    pleaseEnter: '请输入',
    tagName: '标签名称',
    tagTips: '创建位置分组标签信息,位置分组最多创建4个,每个位置分组最多32个标签',
    nodetagTips: '创建功能分组信息,功能分组最多创建31个',
    nextStep: '下一步',
    preStep: '上一步',
    executeTime: '执行时间',
    strategyTarget: '策略对象',
    back: '返回',
    createProjectError: '新建项目失败',
    modifiedProjectInformationFail: '修改项目信息失败',
    pleaseSelectProjectTag: '请选择标签',
    tagRalation: '标签关系',
    pleaseSelectService: '请选择服务',
    count: '数量',
    isProject: '所属项目',
    createAcquistion: '新建领用',
    createReturn: '新建归还',
    createAcquistionSuccess: '新建领用成功',
    createReturnSuccess: '新建归还成功',
    createAcquistionFail: '新建领用失败',
    createReturnFail: '新建归还失败',
    acquistionReturnDate: '领用/归还时间',
    enableAcquisitionSuccess: '采集任务成功',
    enableAcquisitionFail: '采集任务失败',
    enable: '启用',
    disable: '禁用',
    pleaseEnterName: '请输入名称',
    orderNo: '订单号',
    renterId: '租户ID',
    traderId: '商户ID',
    renterName: '租户名称',
    traderName: '商户名称',
    transactionId: '微信订单号',
    rechargeBalance: '充值前余额',
    rechargeAmount: '充值金额',
    refundBalance: '退款前余额',
    refundAmount: '退款金额',
    balance: '扣费前余额',
    amount: '扣费金额',
    consumeElectric: '消耗电量(kWh)',
    refundId: '微信退款订单号',
    applyTime: '申请退款时间',
    sendTime: '退款确认时间',
    rechargeTime: '支付时间',
    createTime: '创建时间',
    applyForRefund: '申请退款',
    RefundInProgress: '退款中',
    RefundSuccessful: '退款成功',
    RefundFailed: '退款失败',
    canceled: '已取消',
    toBePaid: '待支付',
    toBeConfirmed: '待确认',
    paid: '已支付',
    paymentFailed: '支付失败',
    secretKey: 'App Secret',
    apiV3Key: '支付密钥',
    merchantSerial: '序列号',
    pleaseEnterSecretKey: '请输入密钥',
    createTrader: '新增商户',
    traderInformation: '商户信息',
    mchid: '微信商户号',
    pleaseEnterTraderAccount: '请输入微信商户号',
    sex: '性别',
    createRenter: '新增租户',
    checkinDate: '入住日期',
    pleaseEnterCheckinDate: '请输入入住日期',
    feeRate: '电价',
    balances: '余额',
    alarmThreshold: '告警阈值',
    meterInital: '电表底度(kWh)',
    PowerOn: '通电',
    powerFailure: '停电',
    loadCurve: '负荷曲线',
    costControlManagement: '费控管理',
    bizType: '业务类型',
    recharge: '充值',
    rechargeTip: '已提交充值审核，请通知相关人员进行审核。',
    unbind: '解绑',
    bind: '绑定',
    confirmWhetherToUnbind: '确认是否解绑',
    unbindSuccessful: '解绑成功',
    deviceInformation: '设备信息',
    other: '其他',
    notEmptyProtocolConfig: '请填写协议配置',
    cutPower: '停电',
    restorePower: '复电',
    readMeter: '抄表',
    addRepairReport: '报修',
    bindRenter: '绑定租户',
    unbindRenter: '解绑租户',
    confirmWhether: '确认是否',
    restorePowerConfirmWhether: '当前设备欠费，是否确认复电？',
    successfully: '成功',
    meterCurrent: '当前电量(kWh)',
    reportUser: '报修人',
    pleaseEnterReportUser: '请输入报修人',
    pleaseSelectRenter: '请选择租户',
    renter: '租户',
    device: '设备',
    toExamine: '审核',
    rechargeType: '支付方式',
    refundDevice: '退费',
    refund: '退款',
    ResetToero: '清零',
    DeductionFee: '扣费',
    DeductionType: '扣费类型',
    hasPay: '是否开通支付',
    category: '种类',
    categoryName: '种类名称',
    categoryCode: '种类编码',
    functionalGrouping: '功能分组',
    LocationGrouping: '位置分组',
    RefreshTime: '刷新时间',
    RunningTime: '运行时间',
    HardwareVersion: '硬件版本',
    SoftwareVersion: '软件版本',
    Voltage: '电压',
    MeterRatioParameters: '电表变比参数',
    DetailsPage: '详情页面',
    operationPanel: '操作面板',
    essentialInformation: '基本信息',
    AlarmSituation: '告警情况',
    DataWithinSixMonths: '半年内数据',
    TotalPower: '总用电量',
    TotalEnergySaving: '总节能',
    AlarmData: '告警数据',
    BelongingStrategy: '所属策略',
    offlineAlarmTime: '灯离线告警时间',
    collect: '是否采集',
    protocolCode: '解码协议类型',
    pleaseSelectProtocolCode: '请选择解码协议类型',
    hasSwitch: '是否有开关',
    pullSwitch: '拉闸',
    closeSwitch: '合闸',
    checkPower: '射频频点',
    thresholdLow: '触发阈值下限',
    thresholdRate: '触发阈值比例',
    productTest: '成品工装检测',
    reportTime: '告警时间',
    electricityOfDay: '日消耗',
    energy: '累积消耗',
}
