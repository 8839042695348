export default {
    systemName: 'Intelligent lighting management system',
    loginByAccount: 'Login by account',
    loginByPhone: 'Login by phone',
    phoneNumber: 'Phone Number',
    verificationCode: 'Verification Code',
    account: 'Account',
    password: 'Password',
    getCode: 'Get Code',
    resend: 'Resend',
    loginButton: 'Login',
    logout: 'Logout',
    pleaseEnterAccount: 'Please enter account',
    pleaseEnterPassword: 'Please enter password',
    pleasevalidCodeDiv: 'Please enter the verification code',
    pleaseEnterTheVerificationCode: 'Please enter the verification code',
    pleaseEnterPhoneNumber: 'Please enter phone number',
    pleaseEnterCorrectPhoneNumber: 'Please enter correct phone number',
    theVerificationCodeHasBeenSent: 'The verification code has been sent',
    newPassword: 'New Password',
    passwordChangedSuccessfully: 'Password changed successfully',
    passwordChangeFailed: 'Password change failed',
    toggleLight: 'Toggle Light',
    brillianceControl: 'Brilliance Control',
    relay: 'relay',
    cctControl: 'Cct Control',
    colorControl: 'Color Control',
    modifyColor: 'Modify Color',
    fileDistribution: 'File Distribution',
    configurationModificationSucceeded: 'Configuration modification succeeded',
    configurationModificationFailure: 'Configuration modification failure',
    fileDeliverySucceeded: 'File delivery succeeded',
    fileDeliveryFailure: 'File delivery failure',
    failedToObtainTheDeviceStatus: 'Failed to obtain the device status',
    confirm: 'Confirm',
    cancel: 'Cancel',
    search: 'Search',
    reset: 'Reset',
    createAccount: 'Create Account',
    status: 'Status',
    normal: 'Normal',
    forbidden: 'Forbidden',
    operate: 'Operate',
    resetPasswords: 'Reset Passwords',
    edit: 'Edit',
    detail: 'Detail',
    accountName: 'Account Name',
    registrationDate: 'Registration Date',
    startDate: 'Start Date',
    endDate: 'End Date',
    accountNo: 'Account No',
    roles: 'Roles',
    superiorRole: 'SuperiorRole',
    updateDate: 'Update Date',
    createDate: 'Create Date',
    statusChangeSucceeded: 'Status change succeeded',
    pleaseSelectStatus: 'Please select status',
    project: 'Project',
    pleaseSelectRoles: 'Please select roles',
    pleaseSelectSuperiorRole: 'Please select roles',
    pleaseSelectProjects: 'Please select projects',
    accountNamesCanOnlyBeAlphanumericAndNumeric: 'Account names can only be alphanumeric and numeric',
    cannotContainChineseChineseCharactersAndSpaces: 'cannot contain Chinese, Chinese characters and Spaces',
    createdAccountSuccessfully: 'Created account successfully',
    modifiedAccountInformationSuccessfully: 'Modified account information successfully',
    createTenant: 'Create Tenant',
    tenantName: 'Tenant Name',
    uniformCreditCode: 'Uniform Credit Code',
    description: 'Description',
    pleaseEnterTenantName: 'Please enter tenant name',
    pleaseEnterAccountNo: 'Please enter accountNo',
    tenantFullName: 'Tenant Full Name',
    businessLicense: 'Business License',
    area: 'Area',
    address: 'Address',
    contactWay: 'Contact Way',
    cannotContainSpecialCharacters: 'Cannot contain special characters',
    pleaseEnterUniformCreditCode: 'Please enter uniform credit code',
    pleaseEnterTenantFullName: 'Please enter tenant full name',
    pleaseSelectArea: 'Please select area',
    pleaseUploadLogo: 'Please upload Logo',
    pleaseUploadBusinessLicense: 'Please upload business license',
    pleaseEnterDescription: 'Please enter description',
    pleaseEnterAddress: 'Please enter address',
    createTenantSuccessfully: 'Create tenant successfully',
    modifiedTenantInformationSuccessfully: 'Modified tenant information successfully',
    uploadFileSizeCannotExceed1M: 'Upload file size cannot exceed 1M',
    onlyJpgPngImagesCanBeUploaded: 'Only Jpg/Png images can Be uploaded',
    createRole: 'Create Role',
    permissionSetting: 'Permission Setting',
    roleName: 'Role Name',
    index: 'Index',
    pleaseEnterRoleName: 'Please enter role name',
    roleCode: 'Role Code',
    roleDesc: 'Role Description',
    onlyContainLetterCasesNumbersAndUnderscores: 'Only contain letter cases,numbers,and underscores',
    pleaseEnterRoleCode: 'Please enter role code',
    pleaseEnterRoleDesc: 'Please enter role description',
    createRoleSuccessfully: 'Create role successfully',
    modifiedRoleInformationSuccessfully: 'Modified role information successfully',
    createType: 'Create Type',
    typeName: 'Type Name',
    typeDesc: 'Type Description',
    confirmWhetherToDeleteTheType: 'Confirm whether to delete the type',
    confirmWhetherToDelete: 'Confirm whether to delete',
    successfullyDelete: 'Successfully delete',
    del: 'Delete',
    pleaseEnterTypeName: 'Please enter type name',
    pleaseEnterTypeDesc: 'Please enter type description',
    createTypeSuccessfully: 'Create type successfully',
    modifiedTypeInformationSuccessfully: 'Modified type information successfully',
    createProject: 'Create Project',
    projectName: 'Project Name',
    province: 'Province',
    pleaseSelectProvince: 'Please select province',
    projectType: 'Project Type',
    parentProject: 'Parent Project',
    longitude: 'Longitude',
    pleaseEnterLongitude: 'please enter longitude',
    latitude: 'Latitude',
    pleaseEnterLatitude: 'please enter latitude',
    projectDesc: 'Project Description',
    pleaseSelectParentProject: 'Please select parent project',
    pleaseEnterProjectName: 'Please enter project name',
    pleaseSelectProjectType: 'Please select project type',
    createProjectSuccessfully: 'Create project successfully',
    modifiedProjectInformationSuccessfully: 'Modified project information successfully',
    parentType: 'Parent Type',
    pleaseSelectParentType: 'Please select parent type',
    deviceType: 'Device Type',
    pleaseEnterDeviceType: 'Please enter Device type',
    pleaseSelectDeviceType: 'Please select Device type',
    createGateway: 'Create Gateway',
    controlPanel: 'Control Panel',
    sendGroup: 'Group distribution',
    gatewayId: 'Gateway Id',
    pleaseEnterGatewayId: 'Please enter gateway id',
    gatewayType: 'Gateway Type',
    pleaseSelectGatewayType: 'Please select gateway type',
    offLine: 'Off Line',
    onLine: 'On Line',
    error: 'Error',
    pleaseSelectProject: 'Please select project',
    gatewayName: 'Gateway Name',
    groupBelonging: 'Group Belonging',
    confirmWhetherToDeleteTheGateway: 'Confirm whether to delete the gateway',
    gatewayICCID: 'GatewayICCID',
    pleaseEnterGatewayICCID: 'Please enter gateway ICCID',
    pleaseEnterGatewayName: 'Please enter gateway name',
    createGatewaySuccessfully: 'Create gateway successFully',
    modifiedGatewayInformationSuccessfully: 'Modified node information successfully',
    createGatewayGroup: 'Create Gateway Group',
    groupName: 'Group Name',
    pleaseEnterGroupName: 'Please enter group name',
    pleaseEnterTagName: 'Please enter tag name',
    pleaseSelectGroupName: 'Please select group name',
    gatewayDevice: 'Gateway Device',
    pleaseSelectGateway: 'Please select gateway',
    confirmWhetherToDeleteTheGatewayGroup: 'Confirm whether to delete the gateway group',
    createGatewayGroupSuccessfully: 'Create gateway group successfully',
    modifiedGatewayGroupInformationSuccessfully: 'Modified gateway group information successfully',
    createNode: 'Create Node',
    nodeType: 'Node Type',
    pleaseSelectNodeType: 'Please select node type',
    nodeId: 'Node Id',
    pleaseEnterNodeId: 'Please enter node id',
    nodeName: 'Node Name',
    pleaseEnterNodeName: 'Please enter node name',
    confirmWhetherToDeleteTheNode: 'Confirm whether to delete the node',
    createNodeSuccessfully: 'Create node successfully',
    modifiedNodeInformationSuccessfully: 'Modified node information successfully',
    createNodeGroup: 'Create Node Group',
    createTag: 'Create Tag',
    nodeDevice: 'Node Device',
    confirmWhetherToDeleteTheNodeGroup: 'Confirm whether to delete the node group',
    pleaseSelectNode: 'Please select node',
    createNodeGroupSuccessfully: 'Create node group successfully',
    modifiedNodeGroupInformationSuccessfully: 'Modified node group information successfully',
    menuPermissions: 'Menu Permissions',
    buttonPermissions: 'Button Permissions',
    theRolePermissionsWereSetSuccessfully: 'The role permissions were set successfully',
    strategyName: 'Strategy Name',
    createControl: 'Create Control',
    distribution: 'Distribution',
    distriproject: 'Distriproject',
    distriStatus: 'DistriStatus',
    finishTime: 'FinishTime',
    distriTime: 'DistriTime',
    log: 'Log',
    pleaseEnterStrategyName: 'Please enter strategy name',
    pleaseSelectStrategyName: 'Please select strategy name',
    createStrategy: 'Create Strategy',
    executionCycle: 'Execution Cycle',
    whetherToRepeat: 'Whether To Repeat',
    executeOperation: 'Execute Operation',
    periodOfValidity: 'Period Of Validity',
    alarmField: 'Alarm Field',
    upperLimit: 'Upper Limit',
    lowerLimit: 'Lower Limit',
    strategyType: 'Strategy Type',
    pleaseSelectStrategyType: 'Please select strategy type',
    deviceCategory: 'Device Category',
    pleaseSelectDeviceCategory: 'Please select device category',
    takeEffect: 'Take Effect',
    checkAll: 'Check All',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    strategyInformation: 'Strategy Information',
    strategyContent: 'Strategy Content',
    property: 'Service Name',
    attribute: 'Attribute Name',
    pleaseSelectProperty: 'Please select property',
    value: 'Value',
    executionTime: 'Execution Time',
    pleaseSelectExecutionTime: 'Please select execution time',
    operatingMode: 'Operating Mode',
    pleaseSelectOperatingMode: 'Please select operating Mode',
    alarmContent: 'Alarm Content',
    opened: 'opened',
    closed: 'closed',
    confirmWhetherToDeleteTheAlarmStrategy: 'Confirm whether to delete the alarm strategy',
    add: 'add',
    createStrategySuccessfully: 'Create Strategy successfully',
    modifiedStrategyInformationSuccessfully: 'Modified strategy information successfully',
    pleaseSelectSecondLevelGatewayType: 'Please select second-leve gateway type',
    pleaseSetAlarmContent: 'Please set alarm content',
    confirmWhetherToDeleteTheTaskStrategy: 'Confirm whether to delete the task strategy',
    set: 'set',
    true: 'True',
    false: 'False',
    setProperty: 'Set Property',
    setReportingTask: 'Set Reporting Task',
    pleaseSetStrategyContent: 'Please set strategy content',
    pleaseSelectExecutionCycle: 'Please select execution cycle',
    notDelivered: 'Not Delivered',
    delivering: 'Delivering',
    deliverySuccessful: 'Delivery Successful',
    deliveryFailure: 'Delivery Failure',
    confirmWhetherToDeleteTheTask: 'Confirm whether to delete the task',
    confirmWhetherToDeliveryTheTask: 'Confirm whether to delivery the task',
    alarmDistribution: 'Alarm Distribution',
    alarmStrategy: 'Alarm Strategy',
    deliveryAlarmStrategyTime: 'Delivery Alarm Strategy Time',
    documentDeliveryTime: 'Document Delivery Time',
    deviceModel: 'Device Model',
    pleaseSelectDeviceModel: 'Please Select Device Model',
    gatewayModel: 'Gateway Model',
    pleaseEnterGatewayModel: 'Please Enter Gateway Model',
    power: 'power',
    pleaseEnterPower: 'Please Enter Power',
    download: 'download',
    createOtaPackage: 'Create OTA Package',
    otaName: 'OTA Name',
    fileSize: 'fileSize',
    fileName: 'fileName',
    pleaseEnterOtaName: 'Please Enter OTA Name',
    confirmWhetherToDeleteTheOtaPackage: 'Confirm Whether To Delete The OTA Package',
    softwareVersionName: 'Software Version Name',
    softwareVersionNum: 'Software Version Num',
    upgradeType: 'Upgrade Type',
    baseVersionNum: 'Base Version Num',
    verification: 'Verification',
    sign: 'Sign',
    fullUpgrade: 'Full Upgrade',
    incrementalUpgrade: 'Incremental Upgrade',
    otaInformation: 'OTA Information',
    otherInformation: 'Other Information',
    pleaseEnterSoftwareVersionName: 'Please Enter Software Version Name',
    pleaseEnterSoftwareVersionNum: 'Please Enter Software Version Num',
    pleaseSelectVerification: 'Please Select Verification',
    pleaseSelectUpgradeType: 'Please Select Upgrade Type',
    pleaseEnterBaseVersionNum: 'Please Enter Base Version Num',
    uploadUpgradePackage: 'Upload Upgrade Package',
    pleaseUploadUpgradePackage: 'Please Upload Upgrade Package',
    createOatPackageSuccessfully: 'Create OAT Package Successfully',
    modifiedOatPackageInformationSuccessfully: 'Modified OAT Package Information Successfully',
    nodeInfo: 'Node Info',
    gatewayInfo: 'node Info',
    Length12: 'Length Should be 12',
    map: 'Map',
    downloadImportingTemplates: 'Download Importing Templates',
    importingNodeDevice: 'Importing Node Device',
    importingSuccessfully: 'Importing Successfully',
    tip: 'Tips',
    untreated: 'Untreated',
    processing: 'Processing',
    processed: 'Processed',
    faultWarning: 'Fault Alarm',
    upperLimitAlarm: 'Upper Limit Alarm',
    lowerLimitAlarm: 'Lower Limit Alarm',
    eventType: 'Event Type',
    pleaseSelectEventType: 'Please Select Event Type',
    deviceName: 'Device Name',
    deviceId: 'Device ID',
    pleaseEnterDeviceId: 'Please Enter Device ID',
    operator: 'Operator',
    projectInformation: 'Project Information',
    alarmNotifyInformation: 'Alarm Notify Information',
    alarmNotifyWay: 'Alarm Notify Way',
    phone: 'PHONE',
    email: 'EMAIL',
    wxCom: 'WXCOM',
    pleaseEnterPhone: 'Please Enter Phone，Use ";" to enter more ',
    pleaseEnterEmail: 'Please Enter Email，Use ";" to enter more ',
    pleaseEnterWxCom: 'Please Enter WxCom，Use ";" to enter more ',
    deviceStatistics: 'Device Statistics',
    projectNumber: 'Project Number',
    gatewayNumber: 'Gateway Number',
    allNodeNumber: 'All Node Number',
    onlineNodeNumber: 'Online Node Number',
    faultyDeviceNumber: 'give an alarm Device Number',
    energyStatistics: 'Energy Statistics',
    alarmEvents: 'Alarm Events',
    more: 'More',
    alarm: 'Alarm',
    node: 'Node',
    failureEquipment: 'Failure Equipment',
    onlineRate: 'Online Rate',
    faultRate: 'Fault Rate',
    electricityConsumption: 'Electricity Consumption',
    energyConservation: 'Energy Conservation',
    dayAvgPower: 'day Avg Power',
    lastWeek: 'Last Week',
    lastMonth: 'Last Month',
    last3Months: 'Last 3 Months',
    TheTimeHorizonCannotBeGreaterThan180Days: 'The time horizon cannot be greater than 180 days',
    currentPosition: 'Current Position',
    NumberOfAssociatedNodes: 'Number of associated nodes',
    refresh: 'Refresh',
    updateTime: 'Update Time',
    batchDelete: 'Batch Delete',
    validCode: 'validCode',
    assetName: 'AssetName',
    pleaseEnterAssetName: 'Please Enter Asset Name',
    macAddress: 'Mac Address',
    pleaseEnterMacAddress: 'Please Enter Mac Address',
    useStatus: 'Status',
    assetType: 'Asset Type',
    assetTypeId: 'Aasset model',
    assetTypeName: 'Asset Type',
    pleaseEnterAssetType: 'Please Enter Asset Type',
    assetCategory: 'Asset Category',
    installDate: 'Install Date',
    createAsset: 'Ccreate Asset',
    dateOfProduction: 'Date of production',
    pleaseSelectAssetCategory: 'please Select Asset Category',
    pleaseSelectAssetTypeId: 'please Select Asset Model',
    pleaseSelectdateOfProduction: 'please Select date Of Production',
    createAssetSuccess: 'Successfully created asset',
    editAssetSuccess: 'Successfully modified asset',
    DeterminewhetherToDeleteThisAsset: 'Determine whether to delete this asset',
    installAndUse: 'install',
    scrap: 'scrap',
    registration: 'registration',
    ConfirmToScrapThisAsset: 'Are you sure to scrap this asset',
    transfer: 'transfer',
    PleaseSelectTheOperator: 'Please select the operator',
    changeSuccessTenant: 'Transfer successful',
    AssetImport: 'Asset Import',
    Operator: 'Operator',
    PleaseSelectAnOperator: 'Please select an operator',
    requisition: 'requisition',
    errorSelectionAsset: 'Please select the assets that need to be collected',
    PleaseSelectACollectionAccount: 'Please select a account',
    CollectingAccount: 'Collecting account',
    ReturnAccount: 'Return Account',
    SuccessfullyCollected: 'Successfully collected',
    SuccessfullyReturned: 'Successfully Returned',
    PleaseSelectAregisteredAssetCollection: 'Please select a registered asset collection',
    BatchScrapping: 'Batch Scrapping',
    PleaseSelectAssetsThatHaveNotBeenScrapped: 'Please select assets that have not been scrapped',
    PleaseSelectTheAssetThatNeedsToBeScrapped: 'Please select the asset that needs to be scrapped',
    PleaseSelectTheAssetsThatNeedToBeTransferred: 'Please select the assets that need to be transferred',
    BatchHandover: 'BatchHandover',
    return: 'Batch return',
    PleaseSelectTheAssetsThatHaveBeenCollected: 'Please select the assets that have been collected',
    PleaseSelectTheAssetsThatNeedToBeReturned: 'Please select the assets that need to be returned',
    PleaseSelectARegisteredAssetTransfer: 'Please Select A Registered Asset Transfer',
    Recipient: 'accepto Name',
    use: 'use',
    EquipmentModel: 'Equipment Model',
    NodeModel: 'Node Model',
    PleaseSelectTheDeviceModel: 'Please select the device model',
    PleaseSelectANodeModel: 'Please select a node model',
    PleaseSelectAtLeastOneItem: 'Please Select At Least One Item',
    TriggerType: 'Trigger Type',
    PleaseSelectTheTriggerType: 'Please Select The Trigger Type',
    PleaseEnterAttributeValues: 'Please Enter Attribute Values',
    PleaseSelectTheExecutionTime: 'Please select the execution time',
    PleaseSelectAnAttributeName: 'Please Select An Attribute Name',
    ObjectModelManagement: 'Object Model Management',
    ObjectModelList: 'Object Model List',
    modelName: 'Model Name',
    modelAdd: 'Add Model',
    ObjectModelName: 'Object Model Name',
    PleaseEnterTheObjectModelName: 'Please Enter The Object Model Name',
    serviceId: 'service Id',
    PleaseEnterServiceId: 'Please Enter Service Id',
    PleaseEnterServiceName: 'Please Enter Service Name',
    ServiceConfiguration: 'Service Configuration',
    AlarmServiceConfiguration: 'Alarm Service Configuration',
    serviceType: 'service Code',
    PleaseEnterServiceType: 'Please Enter Service Type',
    PleaseEnterciid: 'Please Enter ciid',
    attributeCode: 'attribute Code',
    PleaseAttributeCode: 'Please Enter attribute Code',
    attributeName: 'attribute Name',
    PleaseEnterAttributeName: 'Please Enter Attribute Name',
    AttributeTypeList: 'Attribute Type',
    PleaseSelectAttributeTypeList: 'Please Select Attribute Type',
    AttributeDataType: 'Data Type',
    PleaseSelectAttributeDataType: 'Please Select Data Type',
    MaximumValue: 'Maximum Value',
    PleaseEnterMaximumValue: 'Please Enter Maximum Value',
    minimumValue: 'minimum Value',
    PleaseEnterminimumValue: 'Please Enter Minimum Value',
    step: 'step',
    PleaseEnterStep: 'Please Enter Step',
    unit: 'unit',
    PleaseEnterUnit: 'Please Enter Unit',
    AlarmType: 'Alarm Type',
    PleaseSelectAlarmType: 'Please Select Alarm Type',
    SuccessfullyAddedObjectModel: 'Successfully Added Object Model',
    SuccessfullyModifiedTheObjectModel: 'Successfully Modified The Object Model',
    AddService: 'Add Service',
    deleteService: 'delete Service',
    AddAttribute: 'Add attribute',
    deleteAttribute: 'delete Attribute',
    service: 'service',
    AlarmService: 'Alarm Service',
    attribute2: 'attribute',
    PleaseEnterPhysicalModel: 'Please Enter Physical Model',
    enum: 'enum',
    PleaseEnterEnum: 'Please Enter Enum',
    ViewObjectModel: 'Object Model configuration',
    isShow: 'isShow',
    Thereiscurrent: 'There is currently no physical model for this type, please add a new one here',
    AssetModificationSuccessful: 'Asset modification successful',
    PleaseSelectServiceName: 'Please Select Service Name',
    PleaseDoNotAddAgain: 'Please Do Not Add Again',
    gateway: 'gateway',
    light: 'light',
    importTemplate: 'import Template',
    AreYouSureYouWantToDeleteThisObjectModel: 'Are you sure you want to delete this object model?',
    EnergyConsumptionConfiguration: 'Energy consumption configuration',
    ObjectModelProperties: 'Object Model Properties',
    operatorSymbol: 'operator Symbol',
    constant: 'constant',
    addrealityOperator: 'add reality Operator',
    addratedOperator: 'addratedOperator',
    RulePreview: 'Rule Preview',
    ActualElectricityConsumptionRules: 'Actual electricity consumption rules',
    RulesForRatedElectricityConsumption: 'Rules for rated electricity consumption',
    SuccessfullyEditedEnergyConsumption: 'Successfully edited energy consumption',
    projectTypeName: 'Type Name',
    sbness: 'someoneBrightness',
    nbness: 'noOneBrightness',
    illDelay: 'illuminationRange',
    illRange: 'illuminationDelay',
    readText: 'I have read and agree',
    yhxy: 'Lanchao User Service Agreement',
    and: 'and',
    yszc: 'Lan Chao Privacy Policy',
    PleaseReadTheUserAgreementAndPrivacyPolicy: 'Please read the user agreement and privacy policy',
    maintenance: 'maintenance',
    MaintenanceInformation: 'MaintenanceInformation',
    RepairType: 'Repair Type',
    FaultDescription: 'Fault Description',
    PleaseEnterAFaultDescription: 'Please Enter A Fault Description',
    ImageAttachment: 'Image attachment',
    PleaseSelectARepairType: 'Please select a repair type',
    PleaseUploadPictures: 'Please upload pictures',
    successfullySubmittedRepair: 'Successfully submitted repair',
    resetPassword: 'reset password',
    pleaseEnterNewPassword: 'please Enter New Password',
    AreYouSureYouWantToResetYourPssword: 'Are you sure you want to reset your password',
    ModelCode: 'Model code',
    PleaseEnterTheModelCode: 'Please enter the model code',
    timeFrame: 'time Frame',
    dataStatistics: 'Digital large screen',
    ChangeBinding: 'Binding mode',
    WhetherToStartBindingMode: 'open / close Binding Mode',
    Duration: 'Duration (seconds)',
    PleaseFillInTheDuration: 'Please Fill In The Duration',
    deviceCategoryValue: {
        GATEWAY: 'gateway',
        GATEWAY_LIGHT: 'gateway_light',
        LIGHT: 'light',
    },
    lastLoginTime: 'lastLoginTime',
    lastLogoutTime: 'lastLogoutTime',
    refreshGatewaySuccess: 'refresh gateway suceess',
    refreshGatewayFail: 'refresh gateway failed',
    refreshSuccess: 'refresh suceess',
    refreshFail: 'refresh failed',
    meterData: 'meter data',
    collectData: 'collect Data',
    dataIndicator: 'data indicator',
    pleaseSelectDataIndicator: 'please select data indicator',
    notEmptyProject: 'project can not empty',
    protocol: 'Communication Protocol',
    pleaseSelectProtocol: 'Please select communication protocol',
    protocolConfig: 'protocol config',
    keyOrValueNotEmpty: 'key and value can not empty',
    acquFrequency: 'acquisition frequency:',
    acquStatus: 'acquisition enable:',
    notEmptyUnit: 'unit can not be empty',
    notEmptyValue: 'frequency can not be empty',
    acquisitionConfig: 'acquisition config',
    acquisitionConfigSuccess: 'acquisition config success',
    acquProp: 'acquisition prop:',
    all: 'all',
    warningAcquisitionConfigEnable:
        'The collection task has been started. Disable the collection task in the collection configuration',
    builders: 'builders',
    managers: 'managers',
    acceptors: 'acceptors',
    ops: 'ops',
    deviceCount: 'deviceCount',
    powerLabel: 'power(W)',
    directIllumination: 'directIllumination(LUX)',
    obliqueIllumination: 'obliqueIllumination(LUX)',
    beforeRemodel: 'beforeRemodel(LUX)',
    afterRemodel: 'afterRemodel(LUX)',
    pleaseSelect: 'please select',
    pleaseEnter: 'please input',
    tagName: 'tagName',
    tagTips: 'You can create a maximum of four label groups. Each label group contains a maximum of 32 labels',
    nodetagTips: 'Create location function group information, with a maximum of 31 function groups created',
    nextStep: 'next step',
    preStep: 'pre step',
    executeTime: 'execute time',
    strategyTarget: 'strategy target',
    back: 'back',
    createProjectError: 'create project failed',
    modifiedProjectInformationFail: 'edit project failed',
    pleaseSelectProjectTag: 'please select tag',
    tagRalation: 'tag relation',
    pleaseSelectService: 'please select service',
    count: 'count',
    isProject: 'project',
    createAcquistion: 'create acquistion',
    createReturn: 'create return',
    acquistionReturnDate: 'time',
    enableAcquisitionSuccess: 'enable acquisition task success',
    enableAcquisitionFail: 'enable acquisition task fail',
    enable: 'enable',
    disable: 'disable',
    pleaseEnterName: 'please Enter Name',
    orderNo: 'order number',
    renterId: 'Tenant ID',
    traderId: 'Merchant ID',
    renterName: 'Tenant Name',
    traderName: 'Merchant Name',
    transactionId: 'WeChat order number',
    rechargeBalance: 'Pre recharge balance',
    rechargeAmount: 'Recharge amount',
    refundBalance: 'Balance before refund',
    refundAmount: 'refund amount ',
    balance: 'Balance before deduction',
    amount: 'Deduction amount',
    consumeElectric: 'Power Consumption ',
    refundId: 'WeChat refund order number',
    applyTime: 'Refund application time',
    sendTime: 'Refund confirmation time',
    rechargeTime: 'Payment time',
    createTime: 'Creation time',
    applyForRefund: 'Apply for a refund',
    RefundInProgress: 'Refund in progress',
    RefundSuccessful: 'Refund successful',
    RefundFailed: 'Refund failed',
    canceled: 'Canceled',
    toBePaid: 'To be paid',
    toBeConfirmed: 'To be confirmed',
    paid: 'Paid',
    paymentFailed: 'Payment failed',
    secretKey: 'App Secret Key',
    apiV3Key: 'Api V3 Key',
    merchantSerial: 'merchant Serial',
    pleaseEnterSecretKey: 'Please enter the payment key',
    createTrader: 'New merchants added',
    traderInformation: 'Merchant Information',
    mchid: 'WeChat merchant account',
    pleaseEnterTraderAccount: 'Please enter your WeChat merchant account',
    sex: 'sex',
    createRenter: 'New tenants added',
    checkinDate: 'Check-in date',
    pleaseEnterCheckinDate: 'Please enter the check-in date',
    feeRate: 'rate ',
    balances: 'balance',
    alarmThreshold: 'Alarm threshold',
    meterInital: 'Electricity meter reading(kWh)',
    PowerOn: 'Power on',
    powerFailure: 'power failure',
    loadCurve: 'load curve ',
    costControlManagement: 'Cost control management',
    bizType: 'Business type',
    recharge: 'Recharge',
    rechargeTip: 'Submitted for recharge review, please notify relevant personnel for review.',
    unbind: 'Unbind',
    bind: 'bind',
    confirmWhetherToUnbind: 'Confirm whether to unbind',
    restorePowerConfirmWhether: 'Equipment arrears， Are you sure to restore the power supply?',
    unbindSuccessful: 'Unbind successful',
    deviceInformation: 'Device information',
    other: 'other',
    notEmptyProtocolConfig: 'not Empty Protocol Config',
    cutPower: 'cut Power',
    restorePower: 'restore Power',
    readMeter: 'read Meter',
    addRepairReport: 'addRepair Report',
    bindRenter: 'bind Renter',
    unbindRenter: 'unbind Renter',
    confirmWhether: 'confirm Whether',
    successfully: 'success fully',
    meterCurrent: 'meter Current(kWh)',
    reportUser: 'report User',
    pleaseSelectRenter: 'please Select Renter',
    renter: 'Renter',
    name: 'name',
    device: 'device',
    toExamine: 'to examine',
    rechargeType: 'recharge Type',
    refundDevice: 'refund Device',
    refund: 'refund',
    ResetToero: 'Reset Toero',
    DeductionFee: 'Deduction Fee',
    DeductionType: 'Deduction Type',
    hasPay: 'Whether to activate payment',
    category: 'category',
    categoryName: 'category Name',
    categoryCode: 'category Code',
    functionalGrouping: 'functional Grouping',
    LocationGrouping: 'Location Grouping',
    RefreshTime: 'Refresh Time',
    RunningTime: 'Running Time',
    HardwareVersion: 'Hardware Version',
    SoftwareVersion: 'Software Version',
    Voltage: 'Voltage',
    MeterRatioParameters: 'Meter Ratio Parameters',
    DetailsPage: 'Details Page',
    operationPanel: 'operation Panel',
    essentialInformation: 'essential Information',
    AlarmSituation: 'Alarm Situation',
    DataWithinSixMonths: 'Data Within Six Months',
    TotalPower: 'Total Power',
    TotalEnergySaving: 'Total Energy Saving',
    AlarmData: 'Alarm Data',
    BelongingStrategy: 'Belonging Strategy',
    offlineAlarmTime: 'Lamp offline alarm time',
    collect: 'Whether to collect or not',
    protocolCode: 'Decoding protocol type',
    pleaseSelectProtocolCode: 'Please select the decoding protocol type',
    hasSwitch: 'Is there a switch',
    pullSwitch: 'Pull the switch',
    closeSwitch: 'close the Switch',
    checkPower: 'check Power',
    thresholdLow: 'thresholdLow',
    thresholdRate: 'thresholdRate',
    productTest: 'productTest',
    reportTime: 'reportTime',
    electricityOfDay: 'electricityOfDay',
    energy: 'energy',
}
