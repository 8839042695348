const Api = {
    // 基础服务接口
    common: {
        post: {
            upload: '/common/file/upload',
            traderUpload: '/trader/upload',
            pushBroadcastCommand: '/project/device/pushBroadcastCommand',
            pushCommand: '/project/device/pushCommand',
            pushGroupCommand: '/project/device/pushGroupCommand',
            sendDocument: '/project/device/sendDocument',
            getDeviceModelPage: '/device/model/getPage',
            resetPasswordBySelf: '/account/subAccount/resetPasswordBySelf',
            getCollectDeviceProperties: '/node/getCollectDeviceProperties',
        },
        get: {
            getMessageCaptcha: '/common/api/captcha/getMessageCaptcha',
            getImageCaptcha: '/common/api/captcha/getImageCaptcha',
            getDeviceState: '/project/device/getState',
            getPropertySettingRecords: '/project/device/getPropertySettingRecords',
            getProtocols: '/node/nodeType/getProtocols',
        },
    },
    // 数据报表
    dataReport: {
        post: {
            getDeviceEnergyDataByTime: '/node/getDeviceEnergyDataByTime',
            dataIotdbStatistics: '/project/dashboard/dataIotdbStatistics',
            findDeviceEnergyList: '/project/findDeviceEnergyList',
        },
        get: {
            getDashboardDevices: '/project/dashboard/devices',
            getDashboardEnergy: '/project/dashboard/energy',
            getFaultDevice: '/project/dashboard/getFaultDevice',
            savingEnergy: '/project/dashboard/savingEnergy',
        },
    },
    // 用户管理接口
    userManager: {
        post: {
            login: '/account/api/login',
            // 账户
            getSubAccountPage: '/account/subAccount/getSubAccountPage',
            addSubAccount: '/account/subAccount/addSubAccount',
            editSubAccount: '/account//subAccount/editSubAccount',
            resetSubAccountPassword: '/account/subAccount/resetPassword',
            changeSubAccountStatus: '/account/subAccount/changeStatus',
            deleteAccount: '/account/subAccount/deleteAccount',
            // 运营商
            getTenantPage: '/account/tenant/getTenantPage',
            changeTenantStatus: '/account/tenant/changeTenantStatus',
            addTenant: '/account/tenant/addTenant',
            editTenant: '/account/tenant/editTenant',
            resetTenantPassword: '/account/tenant/resetPassword',
            // 角色
            getRolePage: '/auth/role/getRolePage',
            getRoleTree: '/auth/role/getRoleTree',
            getAccountRoles: '/auth/role/getAccountRoles',
            changeRoleStatus: '/auth/role/changeRoleStatus',
            addRole: '/auth/role/addRole',
            authRole: '/auth/role/authRole',
            editRole: '/auth/role/editRole',
        },
        get: {
            getUserAuth: '/auth/account/getUserAuth',
            getAccountById: '/account/subAccount/getAccountById',
            getTenantById: '/account/tenant/getTenantById',
            getRoleAuthById: '/auth/role/getRoleAuthById',
            getImageCaptcha: '/common/api/captcha/getImageCaptcha',
        },
    },
    // 项目管理接口
    projectManager: {
        post: {
            // 项目类型
            getProjectTypePage: '/project/getTypePage',
            changeProjectTypeStatus: '/project/changeProjectTypeStatus',
            removeProjectType: '/project/removeType',
            addProjectType: '/project/addType',
            editProjectType: '/project/editType',
            // 项目列表
            getProjectPage: '/project/getPage',
            getLowPowerDevices: '/node/getLowPowerDevices',
            getNodeTreePage: '/project/getNodeTreePage',
            changeProjectStatus: '/project/changeProjectStatus',
            addProject: '/project/add',
            editProject: '/project/edit',
            removeProject: '/project/remove',
            download: '/nodeExtend/download ',
            downloadProjectMeterData: '/nodeExtend/downloadProjectMeterData',
            downloadProjectDeviceData: '/project/downloadProjectDeviceData',
            downloadPCODeviceData: '/project/downloadPCODeviceData',
            downloadGroupDeviceData: '/node/downloadGroupDeviceData',
            iotDbEnergyExport: '/project/iotDbEnergyExport',
            addRecord: '/project/receiptRecord/addRecord',
            editRecord: '/project/receiptRecord/editRecord',
            deleteRecord: '/project/receiptRecord/removeRecord',
            // 查询位置分组
            getGroupPage: '/tagGroup/getGroupPage',
            // 新增位置分组
            addTagGrop: '/tagGroup/addTagGrop',
            // 编辑位置分组
            editTagGrop: '/tagGroup/editTagGrop',
            // 删除位置分组
            removeTagGrop: '/tagGroup/removeTagGrop',
            // 添加标签
            addTag: '/tagGroup/addTag',
            // 编辑标签
            editTag: '/tagGroup/editTag',
            // 删除分组
            removeTag: '/tagGroup/removeTag',
            // 分页获取项目设备能耗数据
            getDeviceElectricityDailyPage: '/project/getDeviceElectricityDailyPage',
            // 分页获取负荷曲线数据
            findEnergyMeterPage: '/project/findEnergyMeterPage',
            getMeterDevices: '/node/getMeterDevices',
        },
        get: {
            getProjectTypeById: '/project/getTypeById',
            getRootParentProject: '/project/getRootParentProject',
            getProjectById: '/project/getById',
            getProjectTags: '/project/app/getTagInfoById',
            getAssetsRecords: '/project/receiptRecord/getPage',
            getAlarmTypes: '/alarm/event/getAlarmTypes',
        },
    },
    // 网关管理接口
    gatewayManager: {
        post: {
            // 网关类型
            getGatewayTypePage: '/node/getTypePage',
            changeGatewayTypeStatus: '/node/changeTypeStatus',
            removeGatewayType: '/node/removeType',
            addGatewayType: '/node/addType',
            editGatewayType: '/node/editType',
            // 网关组
            getGatewayGroupPage: '/node/getGroupPage',
            removeGatewayGroup: '/node/removeGroup',
            addGatewayGroup: '/node/addGroup',
            editGatewayGroup: '/node/editGroup',
            // 网关设备
            getGatewayPage: '/node/getPage',
            removeGateway: '/node/remove',
            addGateway: '/node/add',
            editGateway: '/node/edit',
        },
        get: {
            getGatewayTypeById: '/node/getTypeById',
            getGatewayTypeListByParentId: '/gateway/getTypeListByParentId',
            getGatewayGroupById: '/node/getGroupById',
            getGatewayById: '/node/getById',
            getService: '/device/model/getService',
        },
    },
    // 网关管理接口
    nodeManager: {
        post: {
            // 节点类型
            getNodeTypePage: '/node/getTypePage',
            getDeviceProperties: '/node/getDeviceProperties',
            checkBindSmartLight: '/node/checkBindSmartLight',
            changeNodeTypeStatus: '/node/changeTypeStatus',
            removeNodeType: '/node/removeType',
            addNodeType: '/node/addType',
            editNodeType: '/node/editType',
            // 节点组
            getNodeGroupPage: '/node/getGroupPage',
            removeNodeGroup: '/node/removeGroup',
            sendGroup: '/node/app/sendGroup',
            sendFailedGroup: '/node/app/sendFailedGroup',
            addNodeGroup: '/node/addGroup',
            editNodeGroup: '/node/editGroup',
            // 节点设备
            getNodePage: '/node/getPage',
            getNodesPage: '/node/getNodesPage',
            getCanBindNodesPage: '/node/getCanBindNodesPage',
            addNode: '/node/add',
            editNode: '/node/edit',
            removeNode: '/node/remove',
            importNodeDevices: '/node/devices/import',
            batchRemoveNode: '/node/batchRemove',
            editEnergyAlgorithm: '/node/nodeType/editEnergyAlgorithm',
            changeBindMode: '/project/app/device/changeBindMode',
            pullSwitch: '/node/pullSwitch',
            closeSwitch: '/node/closeSwitch',
            getOperateDeviceStatus: '/node/getOperateDeviceStatus',
            // 费控
            cutPower: '/nodeExtend/cutPower',
            restorePower: '/nodeExtend/restorePower',
            readMeter: '/nodeExtend/readMeter',
            addRepairReport: '/nodeExtend/addRepairReport',
            getRepairReportPage: '/nodeExtend/getRepairReportPage',
            bindRenter: '/nodeExtend/bindRenter',
            unbindRenter: '/nodeExtend/unbindRenter',
            getCanBindDevices: '/nodeExtend/getCanBindDevices',
            pageRenters: '/renter/pageRenters',
            addRenter: '/renter/addRenter',
            editRenter: '/renter/editRenter',
            getRenterDevices: '/renter/app/getRenterDevices',
            pageTraders: '/trader/pageTraders',
            addTrader: '/trader/addTrader',
            editTrader: '/trader/editTrader',
            removeTrader: '/trader/removeTrader',
            getRefundRecords: '/order/getRefundRecords',
            getRechargeRecords: '/order/getRechargeRecords',
            pageFeeRecords: '/order/pageFeeRecords',
            bindDevice: '/renter/bindDevice',
            unbindDevice: '/renter/unbindDevice',
            rechargeByHand: '/renter/rechargeByHand',
            rechargeReview: '/renter/rechargeReview',
            refundDevice: '/nodeExtend/refundDevice',
            getStatementAcc: '/order/getStatementAcc',
            updateSharedCost: '/renter/updateSharedCost',
            // 能耗分组
            pageMeteringGroup: '/meteringGroup/pageMeteringGroup',
            addMeteringGroup: '/meteringGroup/addMeteringGroup',
            editMeteringGroup: '/meteringGroup/editMeteringGroup',
            removeMeteringGroup: '/meteringGroup/removeMeteringGroup',
            // 区域
            pageMeteringArea: '/meteringArea/pageMeteringArea',
            addMeteringArea: '/meteringArea/addMeteringArea',
            editMeteringArea: '/meteringArea/editMeteringArea',
            removeMeteringArea: '/meteringArea/removeMeteringArea',
        },
        get: {
            // 获取计量分类
            getNodeMeteringType: '/node/app/getNodeMeteringType',
            getNodeTypeById: '/node/getTypeById',
            getNodeById: '/node/getById',
            getByIdNew: '/node/getByIdNew',
            getStateNew: '/project/device/getStateNew',
            getNodeGroupById: '/node/getGroupById',
            getBindMode: '/project/device/getBindMode',
            getOnlineNodes: '/project/device/getOnlineNodes',
            getStrategyListByNodeId: '/projectStrategy/getStrategyListByNodeId',
        },
    },
    // 告警策略接口
    intelligentAlarm: {
        post: {
            addAlarm: '/alarm/add',
            removeAlarm: '/alarm/remove',
            getAlarmPage: '/alarm/getPage',
            editAlarm: '/alarm/edit',
            executeAlarm: '/alarm/executeAlarmConfig',
            getAlarmRecordPage: '/alarm/event/getRecordPage',
            changeAlarmRecordStatus: '/alarm/event/changeRecordStatus',
            repairEvent: '/alarm/event/repairEvent',
        },
        get: {
            getAlarmContent: '/alarm/getAlarmContent',
            getAlarmById: '/alarm/getById',
            getRepairRecord: '/alarm/event/getRepairRecord',
        },
    },
    // 资产管理
    assetManage: {
        post: {
            getAssetPage: '/asset/getAssetPage',
            getTypePage: '/gateway/getTypePage',
            addAsset: '/asset/addAsset',
            nodeGetTypePage: '/node/getTypePage',
            editAsset: '/asset/editAsset',
            removeAsset: '/asset/removeById',
            changeUseStatus: '/asset/scrapAsset',
            changeTenant: '/asset/changeTenant',
            getTenantPage: '/account/tenant/getTenantPage',
            assetImport: '/asset/import',
            claimAsset: '/asset/claimAsset',
            getSubAccountPage2: '/account/subAccount/getSubAccountPage',
            returnAsset: '/asset/returnAsset',
        },
        get: {},
    },
    // 智能控制接口
    intelligentControl: {
        post: {
            addTask: '/task/add',
            removeTask: '/task/remove',
            getTaskPage: '/task/getPage', // 更新接口 by leehq
            editTask: '/task/edit',
            addControlTask: '/task/control/add',
            removeControlTask: '/task/control/remove',
            editControlTask: '/task/control/edit',
            getControlTaskPage: '/task/control/getPage',
            executeControlTask: '/task/control/execute',
        },
        get: {
            getTaskById: '/task/getById', // 策略详情获取地址修改
            getTaskContent: '/task/getTaskContent',
            getControlTaskById: '/task/control/getById',
            getService: '/device/model/getService',
            getAlarmService: '/device/model/getAlarmService',
        },
    },
    modelManger: {
        post: {
            getPage: '/device/model/getPage',
            add: '/device/model/add',
            edit: '/device/model/edit',
            addModelService: '/node/nodeType/addModelService',
            editModelService: '/node/nodeType/editModelService',
            modeldelete: '/device/model/delete',
            modelImport: '/device/model/import',
        },
        get: {
            getById: '/node/nodeType/getModelService',
            getModelService: '/node/nodeType/getModelService',
            modelGetById: '/device/model/getById',
        },
    },
    // 升级管理
    updateManager: {
        post: {
            getOtaPage: '/project/ota/getPage',
            addOta: '/project/ota/add',
            editOta: '/project/ota/edit',
            removeOta: '/project/ota/remove',
            sendOTA: '/project/device/sendOTA',
            getOtaRecordPage: '/project/ota/getOtaRecordPage',
        },
        get: {
            getOtaById: '/project/ota/getById',
        },
    },
    // 字典
    dict: {
        post: {
            getCategoryLists: '/category/getCategoryList',
        },
        get: {
            getDictByDictType: '/common/dict/getList',
        },
    },
    // meter
    meter: {
        post: {
            meterStatistics: '/project/dashboard/meter/dataStatistics',
            getDataProcessingTask: '/node/meter/getDataProcessingTaskByMeterId',
            addDataProcessingTask: '/node/meter/addDataProcessingTask',
            batchDataProcessingTaskList: '/node/meter/batchDataProcessingTaskList',
            editDataProcessingTask: '/node/meter/editDataProcessingTask',
            exportDataStatistics: '/project/dashboard/meter/dataStatistics/export',
            iotDbExport: '/project/iotDbExport',
            enableTask: '/node/meter/enableDataProcessingTask',
        },
    },
    /**
     * 项目标签
     */
    projectTag: {
        get: {
            removeProjectTag: '/project/app/removeProjectTag',
        },
    },
    /**
     * 项目标签
     */
    projectTagGroup: {
        get: {
            removeProjectTagGroup: '/project/app/removeProjectTagGroup',
        },
        post: {
            removeGroupp: '/node/removeGroup',
        },
    },
    // 售后管理
    afterSales: {
        post: {
            getFeedbackPage: '/problem/feedback/getPage',
            editFeedback: '/problem/feedback/edit',
        },
        get: {},
    },
    // 设备种类管理
    category: {
        post: {
            // 添加设备种类
            addCategory: '/category/addCategory',
            // 编辑设备种类
            editCategory: '/category/editCategory',
            // 删除设备种类
            removeCategory: '/category/removeCategory',
            // 获取设备种类列表
            getCategoryList: '/category/getCategoryList',
        },
        get: {},
    },
}

export default Api
